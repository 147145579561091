import React from "react"
import Layout from "/src/components/layout"
import { Link } from "gatsby"
import { IoCall } from "@react-icons/all-files/io5/IoCall"
import { IoMail } from "@react-icons/all-files/io5/IoMail"
import Seo from '../components/seo'

const ServiceTemplate = ({
  pageContext: {
    data: { name, description, subServices, asset, provider },
  },
}) => {
  const generatedSubServices = subServices.map(service => (
    <article className="context-article" key={service.id}>
      <h2>{service.name}</h2>
      <div
        className="article-text"
        dangerouslySetInnerHTML={{ __html: service.description }}
      ></div>
    </article>
  ))

  return (
    <Layout>
      <Seo title={`${name}`} />
      <main
        className="wrapper sub-service"
        style={{ backgroundImage: `url(${asset[1].url})` }}
      >
        <section className="sub-service-context">
          <article className="context-article">
            <h1>{name}</h1>
            <div
              className="article-text"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          </article>
          {generatedSubServices}
        </section>
        <section className="sub-service-contact">
          <div className="contact-row">
            <button className="controllers-button">
              <a href={`tel:+48${provider.phone}`}>Zadzwoń</a>
            </button>
            <button className="controllers-button">
              <Link to="/kontakt">Kontakt</Link>
            </button>
          </div>
          <div className="contact-row">
            <h3>Dane kontaktowe</h3>
            <p>{provider.name}</p>
            <a className="contact-link" href={`tel:+48${provider.phone}`}>
              <IoCall /> {provider.phone}
            </a>
            <a className="contact-link" href={`mailto:${provider.mail}`}>
              <IoMail /> {provider.email}
            </a>
            <p>
              Bukowiec 127 <br /> 66-300 Międzyrzecz
            </p>
          </div>
        </section>
        <div className="hero-bg"></div>
      </main>
    </Layout>
  )
}

export default ServiceTemplate
